var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service_row_card",attrs:{"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.ITINERARY_SERVICE_OPEN_SIDEBAR_BUTTON},on:{"click":function($event){return _vm.handleClickServiceRowCard(
      _vm.data.item,
      _vm.constants.EDIT_SERVICE_SIDEBAR_TABS_IDS.INFO
    )}}},[(!_vm.isNewService)?_c('div',{staticClass:"service_row_content"},[_c('header',[_c('div',[_c('b-icon',{staticClass:"card-icon",attrs:{"icon":_vm.helpers.showIconByServiceType(_vm.data.item.id_service_type),"alt":"icon"}}),(!_vm.isNewService)?_c('CopyContent',{attrs:{"content":_vm.data.item.code || _vm.newService.code}}):_vm._e()],1),(
          _vm.isAvailableToReplaceService &&
          !_vm.hideElementByCondition &&
          !_vm.disableQuoteByStatus
        )?_c('b-tooltip',{attrs:{"label":"Replace service"}},[_c('button',{staticClass:"service_row_replace_button",on:{"click":function($event){_vm.stopPropagation($event);
            _vm.openServiceReplaceModal(_vm.data.item);}}},[_c('IconReplace')],1)]):_vm._e()],1),_c('TextWithHoverDetail',{attrs:{"message":_vm.data.value.value},scopedSlots:_vm._u([{key:"baseText",fn:function(){return [(_vm.isServiceRecentlyImported && _vm.displayImportServiceButton)?_c('b-tooltip',{staticStyle:{"padding":"0rem"},attrs:{"type":"is-dark","position":"is-top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{staticStyle:{"font-weight":"300"}},[_vm._v("Last imported on "),_c('strong',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.lastServiceImportText))])])]},proxy:true}],null,false,1248932727)},[_c('b-tag',{staticClass:"mb-1",attrs:{"type":"is-info","rounded":""}},[_c('b-icon',{attrs:{"size":"is-small","custom-size":"fa-5x","icon":"swap-vertical-bold"}}),_vm._v(" Recently imported ")],1)],1):_vm._e(),(
            _vm.data.item.optional === _vm.constants.SERVICE_OPTIONAL_TYPES.CONFIRMED
          )?_c('ServiceTag',{attrs:{"type":"dark","message":"Optional Experience Confirmed"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconCheckCircle',{staticStyle:{"height":"0.6rem","width":"0.8rem"}})]},proxy:true},{key:"message",fn:function(){return [_c('span',[_vm._v(" Optional Experience Confirmed")])]},proxy:true}],null,false,4255678713)}):(_vm.data.item.optional)?_c('ServiceTag',{attrs:{"type":"light","message":"Optional Experience"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconGrowthArrow',{staticStyle:{"height":"0.6rem"}})]},proxy:true},{key:"message",fn:function(){return [_c('span',[_vm._v(" Optional Experience ")])]},proxy:true}],null,false,3678049434)}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.data.value.value))])]},proxy:true}],null,false,3109229391)})],1):_vm._e(),(_vm.isNewService)?_c('p',{staticClass:"service_row_card_placeholder",attrs:{"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.SELECT_SERVICE_BUTTON}},[_vm._v(" Select a service ... ")]):_vm._e(),(
      _vm.helpers.isAccommodationPackageService(_vm.data.item.id_service_type) &&
      _vm.data.item.service_price !== null &&
      _vm.data.item.service_price.length > 0
    )?_c('div',{staticClass:"tags_container",on:{"click":function (event) { return _vm.onServiceRoomTypeClick(event, _vm.data.item); }}},_vm._l((_vm.serviceRooms),function(room){return _c('span',{key:room.id,staticStyle:{"position":"relative"}},[(room.room_qty > 1)?_c('span',{staticClass:"tag_alert"},[_vm._v(_vm._s(room.room_qty))]):_vm._e(),_c('ServiceTag',{attrs:{"message":room.romm_name},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(room.romm_name)+" ")])]},proxy:true}],null,true)})],1)}),0):_vm._e(),(
      _vm.helpers.isAccommodationPackageService(_vm.data.item.id_service_type) &&
      (_vm.data.item.service_price === null ||
        _vm.data.item.service_price.length === 0) &&
      !_vm.helpers.isDraftService(_vm.data.item)
    )?_c('span',[_c('ServiceTag',{attrs:{"type":"warning","message":"No rooms for this service"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconInfo')]},proxy:true},{key:"message",fn:function(){return [_c('span',[_vm._v(" No rooms for this service ")])]},proxy:true}],null,false,2697970223)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }