var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseModal',{attrs:{"isComponentModalActive":_vm.modalReorder,"closeModal":_vm.handleReorderModal}},[_c('IconWarning',{staticClass:"icon is-xsmall color-12",attrs:{"slot":"icon"},slot:"icon"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Service dates and prices will be updated!")]),_c('span',{attrs:{"slot":"paragraph"},slot:"paragraph"},[_vm._v("Are you sure to continue? ")]),_c('div',{staticClass:"is-flex",attrs:{"slot":"buttons"},slot:"buttons"},[_c('BaseButton',{staticClass:"mr-4",attrs:{"action":"secondary","size":"medium","disabled":_vm.loading},on:{"click":_vm.handleReorderModal}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("No, take me back")])]),_c('BaseButton',{attrs:{"action":"tercery","size":"medium","disabled":_vm.loading},on:{"click":function($event){_vm.handleReorderModal();
          _vm.reorderServices(_vm.serviceItinerary, _vm.serviceIndex);}}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Yes, continue")])])],1)],1),_c('label',{staticClass:"actionsButtons"},[_c('label',{staticClass:"upAndDownMenu mr-1"},[_c('button',{staticClass:"upAndDownButton up",attrs:{"disabled":_vm.disableQuoteByStatus},on:{"click":function($event){return _vm.validateServiceDatesWhenReorder(
            _vm.serviceItinerary,
            _vm.serviceIndex,
            'up'
          )}}},[_c('IconMenuUp',{staticClass:"icon color-13"})],1),_c('button',{staticClass:"upAndDownButton down",attrs:{"disabled":_vm.disableQuoteByStatus},on:{"click":function($event){return _vm.validateServiceDatesWhenReorder(
            _vm.serviceItinerary,
            _vm.serviceIndex,
            'down'
          )}}},[_c('IconMenuDown',{staticClass:"icon color-13"})],1)]),_c('button',{staticClass:"upAndDownButton",attrs:{"disabled":_vm.disableQuoteByStatus,"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.SERVICE_ACTIONS_BUTTON},on:{"click":_vm.onShowMoreButtonClick}},[_c('IconMore',{staticClass:"icon color-13"})],1)]),_c('b-message',{ref:("popup-" + (_vm.serviceItinerary.id)),staticClass:"PopoverActions",attrs:{"title":"Choose an Action","aria-close-label":"Close message"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('div',{staticClass:"edit_option__container",on:{"click":function($event){_vm.closeServiceActions();
        _vm.handleModalEditServiceModal(
          _vm.serviceItinerary,
          _vm.constants.EDIT_SERVICE_SIDEBAR_TABS_IDS.INFO
        );}}},[_c('a',{attrs:{"disabled":_vm.disableQuoteByStatus}},[_c('IconPencil',{staticClass:"icon color-8 is-xsmall"}),_vm._v(" Edit ")],1)]),_c('div',{on:{"click":function($event){_vm.closeServiceActions();
        _vm.handleDuplicateServiceItinerary(_vm.serviceItinerary.id);}}},[_c('a',{attrs:{"disabled":_vm.disableQuoteByStatus,"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.DUPLICATE_SERVICE_BUTTON}},[_c('IconDuplicate',{staticClass:"icon color-8 is-xsmall"}),_vm._v(" Duplicate "),(_vm.isAccommodationPackageService)?_c('b-tooltip',{staticStyle:{"padding":"0"},attrs:{"label":"Keep in mind that if service dates are equal, duplicated service will be merged","type":"is-warning","position":"is-left"}},[_c('IconWarning',{staticClass:"icon is-xsmall color-12",staticStyle:{"width":"1.2rem","height":"1.2rem"}})],1):_vm._e()],1)]),(_vm.isAvailableToReplaceService)?_c('div',{on:{"click":function($event){_vm.closeServiceActions();
        _vm.onReplaceClick(_vm.serviceItinerary);}}},[_c('a',{attrs:{"disabled":_vm.disableQuoteByStatus}},[_c('IconReplace'),_vm._v(" Replace ")],1)]):_vm._e(),_c('div',{on:{"click":function($event){$event.preventDefault();_vm.closeServiceActions();
        _vm.deleteGroupedServices();}}},[_c('a',{attrs:{"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.DELETE_SERVICE_BUTTON}},[_c('IconTrash',{staticClass:"icon color-8 is-xsmall"}),_vm._v(" Delete")],1)]),(_vm.hasHistory)?_c('div',{staticClass:"history_option__container",on:{"click":function($event){$event.preventDefault();_vm.closeServiceActions();
        _vm.displayServiceHistoryModal(_vm.serviceItinerary.id);}}},[_c('a',[_c('IconInfo',{staticClass:"icon color-8 is-xsmall"}),_vm._v(" Display history")],1)]):_vm._e(),(
        [
          _vm.constants.SERVICE_OPTIONAL_TYPES.OPTIONAL,
          _vm.constants.SERVICE_OPTIONAL_TYPES.REGULAR ].includes(_vm.serviceItinerary.optional)
      )?_c('div',{on:{"click":function($event){$event.preventDefault();_vm.closeServiceActions();
        _vm.updateServiceOptionalField(_vm.serviceItinerary);}}},[_c('a',[_c('IconGrowthArrow',{class:("icon color-8 is-xsmall " + ([
              _vm.constants.SERVICE_OPTIONAL_TYPES.OPTIONAL,
              _vm.constants.SERVICE_OPTIONAL_TYPES.CONFIRMED ].includes(_vm.serviceItinerary.optional)
              ? 'inverted'
              : ''))}),_vm._v(" "+_vm._s(_vm.serviceItinerary.optional === _vm.constants.SERVICE_OPTIONAL_TYPES.REGULAR ? "Make" : "Undo")+" optional")],1)]):_vm._e(),(
        [
          _vm.constants.SERVICE_OPTIONAL_TYPES.CONFIRMED,
          _vm.constants.SERVICE_OPTIONAL_TYPES.OPTIONAL ].includes(_vm.serviceItinerary.optional)
      )?_c('div',{on:{"click":function($event){$event.preventDefault();_vm.closeServiceActions();
        _vm.updateServiceOptionalField(_vm.serviceItinerary, true);}}},[_c('a',[(
            _vm.constants.SERVICE_OPTIONAL_TYPES.OPTIONAL ===
            _vm.serviceItinerary.optional
          )?_c('IconCheckCircle',{class:"icon color-8 is-xsmall"}):_c('IconGrowthArrow',{class:"icon color-8 is-xsmall inverted"}),_vm._v(" "+_vm._s(_vm.serviceItinerary.optional === _vm.constants.SERVICE_OPTIONAL_TYPES.OPTIONAL ? "Confirm optional" : "Undo confirmation")+" ")],1)]):_vm._e(),(_vm.displayImportServiceButton)?_c('div',{staticClass:"update_option__container",on:{"click":function($event){$event.preventDefault();_vm.closeServiceActions();
        _vm.validateServiceImport();}}},[_c('a',[_c('b-tooltip',{staticStyle:{"padding":"0rem"},attrs:{"type":"is-dark","position":"is-top","active":_vm.serviceItinerary.last_import !== null},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{staticStyle:{"font-weight":"300"}},[_vm._v(" Last update on "),_c('strong',[_vm._v(_vm._s(_vm.lastServiceImportText))])])]},proxy:true}],null,false,1227106657)},[_c('b-icon',{staticClass:"mr-1",attrs:{"size":"is-small","custom-size":"mdi-24px","icon":"swap-vertical-bold"}}),_vm._v(" Update Service ")],1)],1)]):_vm._e(),_c('div',[_c('div',{staticStyle:{"font-size":"0.7rem","max-width":"9rem"}},[_c('strong',[_vm._v(_vm._s(_vm.serviceItinerary.edition.user.name))]),_vm._v(" edited on "+_vm._s(_vm.lastServiceEditionText)+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }